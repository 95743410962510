

.dropNav{
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 60px;
    z-index: 10;
    
    flex-direction: row;

    .menu{
        flex: 1 1 0%;
        display: flex;
        
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: var(--main-hfilter2);
        opacity: 1;
        justify-content: center ;
        align-items: center;
        
        gap: 5px;
        
        
        a{
            color: var(--main-htextColor);
            margin: 0px 2px;
            font-weight: 600;
            font-size: 1.5em;
            text-decoration: none;
            transition: 0.3s;
            padding: 4px 16px;
            border-radius: 99px;

        }
        a.isActive, :hover{
            background-color: var(--main-hoverbg);
            color: var(--main-htextColor1);
        }
    }

    

}


@media only screen and (max-width: 820px){
    .dropNav.isActive{
        display: flex;
        animation-duration: 50ms;
        animation-fill-mode: forwards;
    }
}
