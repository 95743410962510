:root,
:root.light{


    --main-radius: 5px;
    --main-padding:10px;

    --main-hfilter: #1b3a4b;
    --main-hfilter2: #065a60;
    --main-placeholderfilter: 0;
    --main-tbackground: #ededed;

    --section-cards: #b7f5faa2;

    --main-shadow: #3131313e;

    --main-hoverbg: #01465d;

    --main-bordercolor: #413e3eb1;


    --main-htextColor: #f8f9fa;
    --main-htextColor1: #acacac;
    --main-ttextColor: #000000;
}

:root.dark{
    --main-radius: 5px;
    --main-padding:10px;

    --main-hfilter: #1b3a4b;
    --main-hfilter2: #044247;
    --main-placeholderfilter: 1;
    --main-tbackground: #1a1c1c;

    --section-cards: #1d2424;

    --main-shadow: #111111;

    --main-hoverbg: #01465d;

    --main-bordercolor: #cecacaa4;

    --main-htextColor: #f8f9fa;
    --main-htextColor1: #acacac;
    --main-ttextColor: #f9f9f9;
}


body{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,'Helvetica Neue', arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', sans-serif;
    background-color: var(--main-tbackground);
    margin: 0;
    font-size: 1em;
    
    
}

main{
    display: flex;
    flex-direction: column;
    align-items: center;
    //max-width: 1000px;
    margin: auto;
     //   padding-left: 70px;
     //   padding-right: 70px;
    color: var(--main-ttextColor);
    
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    padding-bottom: var(--main-padding);

    img{
        border-color: var(--main-bordercolor);
        border-width: 3px;
    }
}

section{
    
    max-width: 1000px;
}


@media only screen and (max-width: 630px){
    main{
        margin: 40px;
        font-size: 1.3em;
    }
    
}

@media only screen and (max-width: 860px) {
    body{
        zoom: 0.9;
        
    }
}
@media only screen and (max-width: 560px) {
    body{
        zoom: 0.8
    }
}
@media only screen and (max-width: 360px) {
    body{
        zoom: 0.7
    }
}

@import url(./_Navbar.scss);

@import url(./_DropNav.scss);

@import url(./_HeadWrap.scss);

@import url(_Left-right.scss);

@import url(./_ImageCut.scss);

@import url(./_DataTable.scss);
