.headWrap{
    display: flex;
    max-height: 40vh;
    padding-top: 55px;
    min-height: 250px;

    box-shadow: 0px 3px 10px 2px var(--main-shadow);

    
    position: relative;
    align-items: center;
    justify-content: center;
    

    background-size: cover;

    .overlay{
        max-height: 100vh;
        top: 55px;
        left: 0;
        right: 0;
        min-height: 250px;
        opacity: 0.6;
        background: linear-gradient(to bottom, var(--main-hfilter), var(--main-hfilter2));
        position: absolute;
        background-position: center;
        background-size: cover;
    
        
    }

    .text{
        z-index: 2;
        color: var(--main-htextColor);
        font-size: 40px;
        font-weight: bold;
        position: absolute;
        padding-top: 60px;
    }

    .menu{
        flex: 1 1 0%;
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: var(--main-hfilter2);
        justify-content: center ;
        align-items: center;
        flex-direction: column;
        
    }

}