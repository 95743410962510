

.navbar{
    position: fixed;
    display: flex;
    align-items: center;
    background: linear-gradient(to bottom, var(--main-hfilter), var(--main-hfilter2));
    
    
    max-height: 65px;
    padding-right: 15px;
    width: 100%;
    z-index: 10;

    box-shadow: 0px 3px 8px 2px var(--main-shadow);


    
    justify-content: space-between;

    
    img{
        position: relative;
        max-height: 65px;
        opacity: 1;
        filter: invert(1);
        
    }

    button{
        display: none;
        position: absolute;
    }


    .menu{
        flex: 1 1 0%;
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
    
        a{
        color: var(--main-htextColor);
        margin: 0 16px;
        font-weight: 600;
        font-size: 1.15em;
        text-decoration: none;
        transition: 0.4s;
        padding: 8px 16px;
        border-radius: 99px;

        

            
        }
        
        .isActive, a:hover{
            background-color: #7291a2;
            color: var(--main-htextColor1);
        }
    }

    

   

}

    .services{
        display: none;
        z-index: 20;
        position: fixed;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 10px;
        
        flex-wrap: nowrap;
        background: linear-gradient(to bottom, var(--main-hfilter2),var(--main-hfilter) );
        top: 65px;
        right: 294px;
        width: 150px;
        
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        height: 140px;

        

        a{
            color: var(--main-htextColor);
            margin: 0 16px;
            font-weight: 600;
            font-size: 1.15em;
            text-decoration: none;
            transition: 0.4s;
            padding: 8px 16px;
            border-radius: 99px;
    
            
        }
    }
    .services.isActive{
        display: flex;
        a{
            background-color:  none;
        }
        a.isActive, a:hover{
            background-color: #7291a2;
            color: var(--main-htextColor1);
        }
    }
    
    


@media only screen and (max-width: 820px){
    .navbar{
        .menu{
            display: none;
    
        }

        

        .darkmode-switch{
            display: none;
        }


        .dropdown{
            display: block;
            position: relative;
            z-index: 10;
            margin-right: 30px;
            user-select: none;
            
    
            appearance: none;
            border: none;
            outline: none;
            background: none;
            cursor: pointer;

            span{
                display: block;
                width: 33px;
                height:4px;
                margin-bottom: 5px;
                position: relative;
        
                background-color: var(--main-htextColor);
                border-radius: var(--main-radius);
                z-index: 10;
                
                transform-origin: 0 0;
                transition: 0.4s;
            }

            
            
        }

        :hover span:nth-child(2){
            transform: translateX(7px);
            background-color: var(--main-htextColor1);
        }

        .isActive span:nth-child(1){
            transform: translate(0px, -2px) rotate(45deg);
        }

        .isActive span:nth-child(2){
            opacity: 0;
            transform: translateX(20px);
    
        }

        .isActive span:nth-child(3){
            transform: translate(-3px, 3px) rotate(-45deg);
        }

        .isActive:hover span{
            background-color: var(--main-htextColor1);
        }
    }

    .services{
        top: 119px;
        right: 44.5%;
        border: none
    }

    
}

@media only screen and (max-width: 820px){
    .services{
        top: 119px;
        right: 43.5%;
    
    }
}