

.image-cut{
    display: flex;
    flex-direction: row;
    align-items: center;
    zoom: 0.9;
    box-shadow: 4px 0px 20px 2px var(--main-shadow);
    background-color: var(--section-cards);
    margin-top: 30px;
    

    .image-cut::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(255, 0, 0, 0.5);
        mix-blend-mode: multiply;
      
    }

    .imageCut{
        
		position: relative;
		display: inline-block;
		
		
        

        img{
            position: relative;
            display: block;
            height: 100%;
            width: 100%;
            aspect-ratio: 4;
            object-fit: cover;
            filter: var(--main-hfilter2);
            
            
            
    
            @media screen and (max-width: 860px){
                aspect-ratio: 3;
            }
    
            @media screen and (max-width: 760px){
                aspect-ratio: 2.5;
            }
    
            @media screen and (min-width: 630px){
                clip-path: polygon(10% 0%,
                    100% 0%,
                    100% 100%,
                    0% 100%
                );
                
                
            }
    
            
        }


        
    }



    .text-wrap{
        
        flex-direction: row;
        padding-left: 80px;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        zoom: 0.9;
        h3{
            text-align: center;
        }
        
    }


    @media screen and (max-width: 630px){
        flex-direction: column;
        .text-wrap{
            padding-left: 50px;
            padding-right: 50px;
        }
    }
    
    div{
        @media screen and (max-width: 630px){
            width: 80%;
            
        }
        @media screen and (min-width: 630px) {
            max-width: 50%;
        }
        

    }    

    
       
    

    


    
    
}



.image-cut.isMirrowed img{
    
    
    

    @media screen and (min-width: 630px){
        clip-path: polygon(0% 0%,
    100% 0%,
    100% 100%,
     10% 100%);
    }
}





.image-cut.flipped{
    img{
        width: 100%;
        object-fit: cover;
        @media screen and (min-width: 630px){
            clip-path: polygon(0% 0%,
            100% 0%,
            90% 100%,
            0% 100%);
        }
    }
    
    .text-wrap{
        padding-left: 20px;
        padding-right: 80px;

        @media screen and (max-width: 630px){
    
        
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    
    

    
}

.image-cut.flipped.isMirrowed img{
    @media screen and (min-width: 630px){
        clip-path: polygon(0% 0%,
    90% 0%,
    100% 100%,
     0% 100%);
    }
}


@media screen and (max-width: 630px){
    .cutCard{
        zoom: 1.2;
        margin-top: 20px;
    }
    
}