



.dataTable{
    display: flex;
    gap: 40px;
    margin-top: 30px;
    align-items: stretch;
    justify-items: center;
    zoom: 1.17;
    
    
    Paper{
        width: 100%;
        height: 100%;
        
    }
        
    img{
        position: relative;
        object-fit: cover;
        width: 200px;
        height: 100%;
        border-radius: 16px;
        box-shadow: 4px 8px 15px 1px var(--main-shadow);
        
    }
    
    div{
        box-shadow: 3px 4px 15px 1px var(--main-shadow);
        border-radius: 20px;
        
        TableContainer{
            position: relative;
            overflow: auto;
            
            Table{
                text-align: left;
                display: flexbox;
            }

        }
        

    }

    .table{
        width: 500px;
    }
    

    @media screen and (max-width: 800px) {
        img{
            display: none;
        }
        gap: 0;
    }

    
}

    