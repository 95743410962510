.left-right {
    display: inline-grid;
    grid-template-columns: 1fr 2fr;
    zoom: 0.9;
    gap: 60px;
    align-items: center;
    

    img{
        max-width: 200px;
        margin: auto;
        width : 100%;
        border-style: solid;
        aspect-ratio: 1;
        border-radius: 50%;
        object-fit: cover;
        grid-area: 'img';
    
        box-shadow: 4px 8px 12px 6px var(--main-shadow);
       
    }


    h2{
        text-align: center;
        grid-area: 'h2';
        
    }

    p{
        text-align: center;
        grid-area: 'p';
        
    }

    .text{
        padding-left: 10px;
        padding-right: 10px;
    }

}



.right-left {
    display: inline-grid;
    grid-template-columns: 2fr 1fr;
    zoom: 0.9;
    
    gap: 60px;
    


    img {
        max-width: 200px;
        margin: auto;
        width : 100%;
        border-radius: 50%;
        object-fit: cover;
        border-style: solid;
        grid-area: 'img';

        box-shadow: 4px 6px 10px 2px var(--main-shadow);
    
        aspect-ratio: 1;
    }



    h2{
        text-align: center;
        grid-area: 'h2';
        
    }

    p{
        text-align: center;
        grid-area: 'p';
        
    }
    .text{
        padding-left: 10px;
        padding-right: 10px;
    }



}

@media only screen and (max-width: 900px){
    .right-left{
        zoom: 90%;
        .text{
            padding-left: 30px;
        }
    }
    .left-right{
        zoom: 90%;
        .text{
            padding-right: 30px;
        }
    }
}

@media only screen and (max-width: 680px){

    .left-right, .right-left{

        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 
        'img'
        'text'
        ;
        gap: 30px;
        .text{
            padding-left: 150px;
            padding-right: 150px;
        }
        

        
    }

}